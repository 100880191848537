import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import "@/plugins/firebase";
import Vuelidate from "vuelidate";
import Toasted from 'vue-toasted';
import BootstrapVue from 'bootstrap-vue';
import axios from 'axios';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import config from './config';
import Moment from 'moment';
import * as VueGoogleMaps from 'vue2-google-maps';
// import jQuery from 'jquery';
import $ from 'jquery';
import VueFormWizard from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'


// // Theme's JS Files         
// import './assets/js/jquery.steps.js';
// import './assets/js/main.js';

window.axios = axios;
require('dotenv').config()
Vue.use(BootstrapVue);
Vue.use(Vuelidate);
Vue.use(VueGoogleMaps);
Vue.use(Moment);
Vue.use(VueFormWizard)

window.jQuery = $;
Vue.use(Toasted, {
  duration: 4000,
  position: 'top-right',
  action : {
    onClick : (e, toastObject) => {
        toastObject.goAway(0);
    }
  }
});

Vue.use(VueGoogleMaps, {
  load: {
      key: 'AIzaSyCwTejmwCRzB2lLd6VkM2w8fRCEoxEpdmo',
      libraries: "places",
  },
  installComponents: true,
});

Vue.config.productionTip = false
let user = JSON.parse(localStorage.getItem('vuex')); 
if(!!user && user && user !== null)
{
  let token = user.user.login.token;
  window.axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
}
window.axios.defaults.baseURL = config.api_url;
window.axios.defaults.headers.common['Content-Type'] = 'application/json';
new Vue({
  router,
  store,
  
  render: h => h(App)
}).$mount('#app')