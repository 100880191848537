import firebase from "firebase";

// Import needed firebase modules
import "firebase/auth";

// Firebase app config
// const config = {
//   apiKey: 'AIzaSyDTaF0j8Ipz1mhI7QEfoUnvHzHK4Ai22js',
//   authDomain: 'deonde-225311.firebaseapp.com',
//   databaseURL: 'https://deonde-225311.firebaseio.com',
//   projectId: 'deonde-225311',
//   storageBucket: 'deonde-225311.appspot.com',
//   messagingSenderId: '217696033954',
//   appId: '1:217696033954:web:1620d87067a42e260b4bfb'
// };


const config = {
  apiKey: 'AIzaSyCwTejmwCRzB2lLd6VkM2w8fRCEoxEpdmo',
  authDomain: 'hungrry.firebaseapp.com',
  databaseURL: 'https://hungrry.firebaseio.com',
  projectId: 'hungrry',
  storageBucket: 'hungrry.appspot.com',
  messagingSenderId: '322288595955',
  appId: '1:322288595955:web:2dd3f7fead6b316614d886'
};

// Init our firebase app
firebase.initializeApp(config);

export default firebase;