import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)



const router = new VueRouter({
  mode: 'history',
  // component: Base,
  routes: [ {
    path: '/',
    name: 'Home',
    component: () =>import('@/views/Home'),
    // children: routes,
  },
  {
    path: '/order_details/:order_id',
    name: 'order-details',
    component: () => import('@/views/OrderDetails'),
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/About.vue'),
  },
  // {
  //   path: '/:catchAll(.*)',
  //   component: () =>import('@/views/Home'),
  // },
]
})

export default router
